@media only screen and (max-width: 600px) {
  .profilePic{
    height: 70px;
    margin-top: 25px;
  }

  .profile {
    margin-left: 30px;
  }

  .linkIcon {
    height: 15px;
    margin: 4px;
    margin-bottom: 0;
  }

  .content {
    width: 90vw;
  }

  .bioTextContainer {
    margin-bottom: 4vh;
    line-height: 20px;
    margin-right: 0px;
    padding-bottom: 25px;
  }

  .headerText{
    font-size: 15px;
    margin-top: 10px;
  }
  
  .paragraphText{
    font-size: 12px;
    margin-top: 5px;
  }
  
  .subtitleText {
    font-size: 14px;
    margin-top: 15px;
  }
  
  .imageLabel {
    font-size: 13px;
  }

  .bioText {
    margin-left: 30px; 
    margin-right: 30px;
  }

  .profilePic {
    border-radius: 50%;
    border:7px solid #c4c9cd7a;
  }
  
  .profilePic:hover {
    border:7px solid #c4c9cdab;
    transition-duration: 0.5s;
    transition-timing-function: ease;
  }
}
 
/* Small screen devices (600px and above) */
@media only screen and (min-width: 600px) {
  .profile {
    margin-left: 40px;
  }

  .profilePic{
    margin-top: 30px;
    height: 80px;
  }

  .linkIcon {
    height: 16px;
    margin: 5px;
  }

  .content {
    width: 80vw;
  }

  .bioTextContainer {
    padding-bottom: 30px;
    line-height: 23px;
    margin-top: 20px;
    margin-right: 0px;
  }

  .headerText{
    font-size: 16px;
  }
  
  .paragraphText{
    font-size: 13px;
    margin-top: 10px;
  }
  
  .subtitleText {
    font-size: 15px;
    margin-top: 30px;
  }

  .bioText {
    margin-top: 6vh;
  }

  .imageLabel {
    font-size: 13px;
  }

  .bioText {
    margin: auto;
    margin-left: 40px; 
    margin-right: 40px;
  }

  .profilePic {
    border-radius: 50%;
    border:7px solid #c4c9cd7a;
  }
  
  .profilePic:hover {
    border:7px solid #c4c9cdab;
    transition-duration: 0.5s;
    transition-timing-function: ease;
  }
}
 
/* Medium screen devices (768px and above) */
@media only screen and (min-width: 768px) {
  .profilePic{
    height: 110px;
  }

  .linkIcon {
    height: 18px;
    margin: 6px;
  }

  .content {
    width: 78vw;
  }

  .bioTextContainer {
    line-height: 25px;
    margin-right: 0px;
  }

  .headerText{
    font-size: 18px;
  }
  
  .paragraphText{
    font-size: 14px;
    margin-top: 10px;
  }
  
  .subtitleText {
    font-size: 15px;
    margin-top: 30px;
  }

  .profileContainer {
    width: 10%;
    color: white;
    margin-left: 20px;
  }

  .linkIconContainer {
    text-align: center;
  }

  .bioText {
    margin-top: 6vh;
  }

  .imageLabel {
    font-size: 15px;
  }

  .linkIconContainer{
    display: flex;
  }

  .bioText {
    margin: auto;
    margin-left: 50px; 
    margin-right: 50px;
  }

  .profilePic {
    border-radius: 50%;
    border:7px solid #c4c9cd7a;
  }
  
  .profilePic:hover {
    border:7px solid #c4c9cdab;
    transition-duration: 0.5s;
    transition-timing-function: ease;
  }
}
 
/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) {
  .profilePic{
    height: 120px;
  }

  .linkIcon {
    height: 19px;
    margin: 8px;
  }

  .content {
    width: 75vw;
  }

  .bioTextContainer {
    margin-bottom: 0px;
    line-height: 30px;
    margin-top: 6vh;
  }

  .headerText{
    font-size: 20px;
  }
  
  .paragraphText{
    font-size: 15.5px;
    margin-top: 10px;
  }
  
  .subtitleText {
    font-size: 17px;
    margin-top: 30px;
  }

  .profileContainer {
    width: 25%;
  }

  .content {
    display: flex;
    flex-direction: row;
  }

  .profile {
    float: right;
  }

  .linkIconContainer {
    text-align: center;
  }

  .bioText {
    margin-top: 6vh;
  }

  .imageLabel {
    font-size: 16px;
  }

  .bioText {
    margin: auto;
    margin-left: 50px; 
    margin-right: 50px;
  }

  .profilePic {
    border-radius: 50%;
    border:10px solid #c4c9cd7a;
  }
  
  .profilePic:hover {
    border:10px solid #c4c9cdab;
    transition-duration: 0.5s;
    transition-timing-function: ease;
  }
}
 
/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {

  .profilePic{
    height: 160px;
  }

  .linkIcon {
    height: 23px;
    margin: 10px;
  }

  .content {
    width: 75vw;
  }

  .headerText{
    font-size: 22px;
  }
  
  .paragraphText{
    font-size: 17px;
    margin-top: 10px;
  }
  
  .subtitleText {
    font-size: 19px;
    margin-top: 30px;
  }

  .bioTextContainer {
    margin-bottom: 10px;
    line-height: 30px;
    width: 77%;
    margin-top: 6vh;
    margin-right: 25px;
  }

  .profileContainer {
    width: 28%;
  }

  .content {
    display: flex;
    flex-direction: row;
  }

  .profile {
    float: right;
    margin-right: 40px;
  }

  .linkIconContainer {
    text-align: center;
  }

  .bioText {
    margin-top: 6vh;
  }

  .imageLabel {
    font-size: 18px;
  }

  .bioText {
    margin: auto;
    margin-left: 50px; 
    margin-right: 50px;
  }

  .profilePic {
    border-radius: 50%;
    border:10px solid #c4c9cd7a;
  }
  
  .profilePic:hover {
    border:10px solid #c4c9cdab;
    transition-duration: 0.5s;
    transition-timing-function: ease;
  }
}

.linkIcon {
  opacity: 0.6;
}

.linkIcon:hover {
  opacity: 0.9; 
  transition-duration: 0.5s;
  transition-timing-function: ease;
}

.headerText{
  font-weight: bold;
}

.subtitleText {
  font-weight: bold;
}


.content {
  font-family: 'Inter';
  background: white;
  border-radius: 15px;
}

.profileContainer {
  color: white;
}


.App{
  min-height: 100vh;
  width: 100vw;
  display: flex;
}

.container {
  margin: auto;
  padding-top: 10vh;
  padding-bottom: 10vh;

}

.imageLabel{
  color: white;
  padding-bottom: 5px;
  margin-top: 15px;
  text-align: right;
}

.textLink {
  position: relative;
  color: #125898;
  text-decoration: none;
}

.textLink:hover {
  border-bottom: 1.7px solid #1257987a;
  transition-duration: 0.1s;
}

.wave {
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}
